<template>
  <div v-bind="$attrs">
    <customer-select2-ajax
      ref="select2"
      required="required"
      :partner-id="partnerId"
      :items="items"
      :initial-value="initialValue"
      :name="name"
      :multiple="multiple"
      :route="route"
      :can-create="canCreate"
      @request-creation="$emit('request-creation')"
      @input="set"
      @select="(v) => $emit('input', v)"
    />

    <slot />
    <slot name="helper" />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import CustomerSelect2Ajax from '../../common/CustomerSelect2Ajax.vue';
import apiRoutes from '../../../router/api';

const { mapFields } = createHelpers({
  getterType: 'contact/select/getField',
  mutationType: 'contact/select/updateField',
});

export default {
  name: 'ContactSelection',
  components: {
    CustomerSelect2Ajax,
  },

  props: {
    canCreate: { type: Boolean, required: false, default: true },
    partnerId: { type: Number, required: true },
    centerId: { type: Number, required: false, default: null },
    productId: { type: Number, required: false, default: null },
    initialValue: { type: Array, required: false, default: () => [] },
    name: { type: String, required: false, default: '' },
    multiple: { type: Boolean, default: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  emits: ['input', 'request-creation'],
  computed: {
    ...mapFields(['items']),
    route() {
      const base = apiRoutes.contacts.getAll;
      const params = new URLSearchParams();
      params.set('customerContacts.status', 'active');
      if (this.partnerId) {
        params.set('partner', this.partnerId);
      }
      if (this.centerId) {
        params.set('center', this.centerId);
      }
      if (this.productId) {
        params.set('product', this.productId);
      }
      return `${base}?${params.toString()}`;
    },
  },
  methods: {
    ...mapActions('contact/select', ['add', 'set']),
    appendOption(id, text) {
      this.$refs.select2.appendOption(id, text);
    },
  },
};
</script>
