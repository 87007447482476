<template>
  <vue-select
    ref="select"
    v-model="localItems"
    v-bind="$attrs"
    :required="false"
    :settings="select2settings"
    class="vue-select2"
    @select="handleSelect"
  />
</template>

<script>
import Select2 from 'v-select2-component';

export default {
  name: 'CustomerSelect2Ajax',
  components: {
    'vue-select': Select2,
  },
  props: {
    partnerId: { type: Number, required: true },
    items: { type: Array, required: true },
    initialValue: { type: Array, required: false, default: () => [] },
    route: { type: String, required: true },
    multiple: { type: Boolean, default: () => true },
    disabled: { type: Boolean, required: false, default: false },
    canCreate: { type: Boolean, required: false, default: true },
  },
  emits: ['input', 'request-creation', 'select'],
  computed: {
    /** @return Select2 */
    select2settings() {
      return {
        theme: 'bootstrap',
        disabled: this.disabled,
        ajax: {
          delay: 400,
          url: this.route,
          dataType: 'json',
          data(params) {
            return {
              search: params.term,
            };
          },
          processResults(response) {
            return {
              results: response.map((rawCustomer) => ({
                id: rawCustomer.id,
                text: `${rawCustomer.label || ''}`,
              })),
            };
          },
        },
        multiple: this.multiple,
        debug: false,
        tags: this.canCreate,
        createTag: this.canCreate
          ? () => ({
              id: this.newContactMarker,
              text: this.newClientText,
              newOption: true,
            })
          : undefined,
      };
    },
    newClientText() {
      return this.trans('client.new.title');
    },
    newContactMarker: () => '__CREATE_NEW__',
    localItems: {
      get() {
        return this.items;
      },
      set(i) {
        // Filter special value for new contact from data sent to vuex
        // eslint-disable-next-line no-nested-ternary
        const filteredInput = Array.isArray(i)
          ? i.filter((v) => v !== this.newContactMarker)
          : i !== this.newContactMarker
            ? [i]
            : [];
        this.$emit('input', filteredInput);
      },
    },
  },
  // Needed to prepopulate the form
  mounted() {
    if (this.$props.initialValue.length) {
      // Create options in select2
      this.$props.initialValue.forEach(({ id, text }) => {
        this.appendOption(id, text);
      });
    }
  },
  created() {
    // Set initial values in vuex
    this.localItems = this.$props.initialValue.map(({ id }) => id.toString());
  },
  methods: {
    // Emit request-creation event if 'create new option' is clicked
    handleSelect(e) {
      if (e.id === this.newContactMarker) {
        this.$emit('request-creation');
      } else {
        this.$emit('select', e);
      }
    },
    // Needed to have Select2 display options before any fetch
    // use select2 templating function ?
    appendOption(id, text) {
      /** @var HTMLOptionElement */
      const opt = document.createElement('option');
      opt.setAttribute('selected', 'selected');
      opt.setAttribute('value', id);
      opt.innerText = text;

      this.$refs.select?.select2?.append(opt).trigger('change');
    },
  },
};
</script>
